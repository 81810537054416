import { PageLayout } from "../../layouts/page";
import { Button } from "@lleed-partners/factor";
import { Download32, ArrowRight32 } from "@carbon/icons-react";
import { Icon } from "../../fragments/Icon";
import { useI18next, Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import { useEffect } from "react";
import { graphql } from "gatsby";

function downloadFile(file) {
  if (!window) {
    return;
  }

  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = file;
  link.download = "Lleed and Partners - FFA Intraday Data";

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    link.parentNode.removeChild(link);
  }, 0);
}

export default () => {
  const { t } = useI18next();

  useEffect(() => {
    downloadFile("/brochures/ffa-intraday.zip");
  });

  return (
    <PageLayout title={t("Downloading your file...")}>
      {t(
        "Your download should start automatically. If it doesn't, please click the button below."
      )}
      <div style={{ height: "2rem" }} />
      <span>
        <Button
          fullWidth={false}
          intent="grey"
          as="a"
          href="/brochures/ffa-intraday.zip"
          rightIcon={<Icon icon={<Download32 />} />}
        >
          {t("Download")}
        </Button>
      </span>
      <div style={{ height: "4rem" }} />
      <Button
        size="large"
        as={GatsbyLink}
        to="/"
        rightIcon={<Icon icon={<ArrowRight32 />} />}
      >
        {t("Get back home")}
      </Button>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
